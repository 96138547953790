.type-connector {
  background-color: #000;
  width: 50px;
  height: 2px;
  margin: 0 10px;
  color: #666;
}
.instructions {
  opacity: 0.55;
  font-size: 0.65em;
}
/*# sourceMappingURL=src/pages/smart-search/results/resultItem.css.map */