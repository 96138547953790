.layout-padding {
  max-width: 768px;
}
.searching {
  margin: 20px;
  height: 50px;
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
/*# sourceMappingURL=src/pages/smart-search/results/mobile.css.map */