




















































































































































.q-card
  width 80vw
  max-width 600px

.q-item > *
  width 100%
