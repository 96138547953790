















































.layout-padding
  max-width 768px
