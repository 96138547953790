







































.tabs
  max-width 100vw
.tab
  opacity 0.5
.hasResults
  opacity 1
  animation 0.1875s ease-out 4 dance

.smol
  font-size 0.65em
  font-weight bold

@keyframes dance
  0%
    transform rotate(0deg)
  25%
    transform rotate(-5deg)
  75%
    transform rotate(5deg)
  100%
    transform rotate(0deg)
