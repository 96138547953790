.content-card {
  min-width: 456px;
}
@media only screen and (max-width: 456px) {
  .content-card {
    min-width: inherit;
  }
}
.loader {
  min-height: 56px;
  height: 56px;
  max-height: 56px;
  display: flex;
  justify-items: center;
  align-items: center;
}
.q-card {
  position: relative;
  background-color: #fff;
}
.q-card > * {
  color: #333;
}
.price {
  font-size: 1.5em;
  margin: 8px 0;
}
.item {
  display: flex;
  justify-content: flex-start;
}
.dots {
  display: flex;
  margin: auto 0;
  margin-left: 8px;
}
@media (min-width: 768px) {
  .dots {
    margin-left: 16px;
  }
}
.dot {
  height: 5px;
  width: 5px;
  background: #eee;
  border-radius: 50%;
  margin-right: 6px;
}
@media (min-width: 768px) {
  .dot {
    height: 10px;
    width: 10px;
  }
}
.duration {
  flex-basis: 65%;
}
.tags {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-basis: 35%;
}
.tag {
  margin-left: 10px;
}
/*# sourceMappingURL=src/pages/smart-search/results/content-card.css.map */