









































































































































































.code
  height 25vh
  width 100%
  font-size 8vmax
  color rgba(255, 255, 255, .7)
  overflow hidden
  background-image url('~assets/cities/London.jpg')
  background-position: 0 60%;
  background-size: 100% 300%;
  text-overflow: clip;

.form
  margin-top -64px
